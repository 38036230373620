
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.fa-check-circle {
  color: #2FB96C;
}

.fa-clock {
  color: #5577ED;
}

.fa-times-circle {
  color: #E54661;
}
