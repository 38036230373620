
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.grid {
  grid-template-columns: 1fr;
  grid-auto-flow: row dense;
  grid-gap: calc(#{$isotope-grid-gap-row} / 2) calc(#{$isotope-grid-gap-column} / 2);
  display: grid;
}

.item {
  padding-bottom: 100%;

  &.item--widecontent, &.item--ultrawidecontent {
    padding-bottom: 50%;
  }
}

@include media-breakpoint-up(sm) {
  .grid {
    grid-gap: $isotope-grid-gap-row $isotope-grid-gap-column;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(lg) {
  .grid {
    grid-template-columns: repeat(var(--grid-column-count, 4), 1fr);
  }

  .item {
    padding-bottom: 50%;
  }

  .item--content {
    grid-row: span 1;
    grid-column: span 1;
    padding-bottom: 100%;
  }

  .item--widecontent {
    grid-row: span 1;
    grid-column: span 2;
  }

  .item--ultrawidecontent {
    grid-row: span 1;
    grid-column: span 4;
    padding-bottom: 25% !important;
  }

  .item--highcontent {
    grid-row: span 2;
    grid-column: span 1;
  }

  .item--largesquarecontent {
    grid-row: span 2;
    grid-column: span 2;
    padding-bottom: 100%;
  }
}
