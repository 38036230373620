
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-component] {
  // OVERRIDE inner-height CSS var to match fandom player height which is correct
  --inner-height: calc(var(--vh, 1vh) * 100);
  --sidebar-width: 26.5rem;

  .fullscreen-container {
    background-color: var(--theme-background);
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr var(--sidebar-width);
    grid-template-rows: repeat(2, auto);
    grid-auto-rows: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    &.no-sidebar {
      display: flex;
      flex-direction: column;
    }

    .embed-main-content { grid-area: 1 / 1 / 2 / 2; }
    .live-event-interactive-sidebar { grid-area: 1 / 2 / 3 / 3; }
    .stripes { grid-area: 2 / 1 / 3 / 2; }

    @include media-breakpoint-down(md) {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;

      .embed-main-content { grid-area: 1 / 1 / 2 / 2; }
      .live-event-interactive-sidebar { grid-area: 2 / 1 / 3 / 2; }
      .stripes { grid-area: 3 / 1 / 4 / 2; }

      @media screen and (orientation:landscape) {
        --sidebar-width: 40%;

        .backscroll {
          display: block;
          height: var(--inner-height);
        }
      }
    }

    &.embed-fullscreen {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      position: relative;
      background-color: $black;

      &.unsupported-fullscreen {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 1090;
        background-color: $black;
        height: var(--inner-height);
        min-height: var(--inner-height);
        max-height: var(--inner-height);
        flex: 1 1 var(--inner-height);

        .embed_content {
          height: 100% !important;
        }
      }

      .embed-main-content {
        width: 100%;
        transition: all .4s ease-in-out;
      }

      .actions, .actions-bottom {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 2rem;
        z-index: 1;

        i {
          cursor: pointer;
        }
      }

      .show-controls + .actions-bottom {
        bottom: calc(#{$player-control-bar-height} + #{$spacer * 2});

        @include media-breakpoint-down(md) {
          bottom: calc(#{$player-control-bar-height} + #{$spacer * 1.25});
        }
      }

      .actions-bottom {
        transition: bottom .4s;
        bottom: 1rem;
        top: auto;
        z-index: 9999;

        &--controlbar {
          bottom: 50px;
        }

        &__sidebar {
          cursor: pointer;
          font-size: 1.5rem;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .live-event-interactive-sidebar {
        z-index: 1090;
        padding: 0;
        top: 0;
        right: 0;
        width: var(--sidebar-width);
        display: flex;
        flex-direction: column;
        margin-right: calc(-1 * var(--sidebar-width));
        transition: all .4s ease-in-out;
      }

      &.active-side-nav {
        .embed-main-content {
          width: calc(100% - #{var(--sidebar-width)});
        }

        .live-event-interactive-sidebar {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(xs) {
        display: grid;

        .embed-main-content {
          min-height: var(--inner-height);
        }

        .live-event-interactive-sidebar {
          width: 100%;
          height: calc(var(--inner-height) - (100vw / 16 * 9));
        }

        &.active-side-nav {
          .embed-main-content {
            width: 100%;
            min-height: calc(100vw / 16 * 9);
          }
        }
      }

      .stripes {
        display: none;
      }
    }

    [comments-component] {
      [comment-add-component] {
        .form-container {
          position: relative;

          .emoji-picker-wrapper {
            top: 50px;
          }

          form {
            width: 100%;

            .new-comment-area {
              border: 1px solid !important;
            }
          }
        }
      }

      [comment] {
        .comment-preview {
          &__name, &__text {
            color: var(--theme-color);
            text-shadow: none;
          }

          .fa-reply {
            color: var(--theme-color);
          }
        }

        .comment__with-parent {
          &::before {
            background-color: var(--theme-color);
          }

          .parent-comment::before {
            background: black;
          }
        }
      }
    }
  }

  .backscroll {
    display: none;
  }

  // styles to apply to all modals in this place
  // TODO: better put it somewhere else?
  [modal] {
    .live-event-modal__background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      pointer-events: none;
      z-index: $zindex-modal-backdrop;
      background-color: rgba($black, 0.75);
    }

    .live-event-modal {
      top: 0;
      left: 0;
      position: fixed;
      z-index: $zindex-modal;
    }
  }
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }

  .player-side {
    display: none !important;
  }
}
