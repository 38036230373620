h1, .h1 {
  font-size: 3rem;
}

h2, .h2 {
  font-size: 2.25rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

@include media-breakpoint-down(sm) {
  h1, .h1 {
    font-size: 2rem;
  }
  
  h2, .h2 {
    font-size: 1.625rem;
  }
  
  h3, .h3 {
    font-size: 1.375rem;
  }
  
  h4, .h4 {
    font-size: 1.125rem;
  }

}

blockquote {
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  color: #{$quote-color};
  font-style: italic;
}

blockquote p {
  display: inline;
}
