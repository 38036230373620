
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[text-section-component] {

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
  }
}
