
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoints-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .col {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .card {
    border: 2px solid var(--theme-color);
    border-radius: .5rem;

    &-title {
      display: flex;
      justify-content: center;
      flex: 1;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;

      .btn-block {
        width: 70%;
      }
    }

    &-text {
      flex: 1;
    }

    &-footer {
      border-top: 0;

      &:last-child {
        border-radius: 0 0 calc(.5rem - 2px) calc(.5rem - 2px);
      }
    }
  }

  .icon {
    width: 6rem;
    height: auto;
  }
}
