
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$logo-height: 2.575rem;
$header-height: 4rem;

[navbar-component] {
  min-height: $header-height;
  transition: background-color $animation-time-fast linear;
}

.navbar-brand {
  min-height: calc(#{$header-height} - 1rem);
}

.nav-item {
  .nav-item-url {
    color: $white;
    text-decoration: none;
  }

  @include hover {
    .nav-item-url {
      color: $link-color;
    }
  }

  .bg-white & {
    .nav-item-url {
      color: $black;
    }

    @include hover {
      .nav-item-url {
        color: $link-hover-color;
      }
    }
  }
}

.navbar-logo {
  height: $logo-height;
  transition: filter .25s;

  @include media-breakpoint-up(lg) {
    height: $logo-height + 1;
  }

  .bg-white & {
    filter: grayscale(100%) brightness(0);
  }
}
