
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  transition: all .25s;

  video {
    object-fit: cover;
  }

  &:not([href]):hover {
    color: var(--theme-color);
  }

  .tile__icon {
    width: 4rem;
  }

  .tile__lock-icon {
    right: 0;
    width: 7rem;
    bottom: 2rem;
    background-color: $primary;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }
}
