
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$logo-size: 7.5rem;
$social-logo-size: 2rem;

.logo {
  width: $logo-size * 1.1;
  height: $logo-size;
}

.social-url {
  transition: filter .25s;

  @include hover {
    filter: invert(83%) sepia(12%) saturate(187%) hue-rotate(359deg) brightness(93%) contrast(89%);
  }
}

.social-logo {
  width: $social-logo-size;
  height: $social-logo-size;

  @include media-breakpoint-up(lg) {
    width: $social-logo-size + 0.5;
    height: $social-logo-size + 0.5;
  }
}

.nav-link {
  color: $black;

  @include hover {
    color: $link-hover-color;
  }
}
