// Colors
$black: #000;
$white: #fff;
$signal-white: #dee0df;
$warm-white: #efe9dd;
$silk-grey: #a69c8c;
$warm-orange: #ffc6a2;

// Override bootstrap colors
$primary: $black;
$link-color: $silk-grey;
$link-hover-color: darken($silk-grey, 15%);
$body-color: $black;


$border-width: 2px;
// Buttons
$btn-border-radius: 0;
$btn-font-size: .875rem;
$btn-font-weight: 700;

// Fonts
$enable-responsive-font-sizes: true;
$font-weight-base: 300;
$font-family-base: "Mabry", sans-serif;
$headings-font-weight: 700;
$headings-font-family: "Mabry", sans-serif;
$headings-margin-bottom: 1rem;

$h1-font-size: 2.875rem;
$h2-font-size: 2.25rem;
$h3-font-size: 1.5rem;
$font-size-base: 1.125rem;

$line-height-base: 1.66;

@import "~src/stylesheets/fandom_variables";

// Inputs
$input-color: $black;
$input-border-color: $black;
$input-placeholder-color: $dark;

$custom-select-border-color: $black;
$custom-select-border-radius: 0;
$custom-select-box-shadow: none;

// Add colors to the bootstrap ones
$nettare-redazione-colors:(
  "black": $black,
  "white": $white,
  "signal-white": $signal-white,
  "warm-white": $warm-white,
  "silk-grey": $silk-grey,
  "warm-orange": $warm-orange
);

$colors: map-merge($colors, $nettare-redazione-colors);
$theme-colors: map-merge($theme-colors, $nettare-redazione-colors);
