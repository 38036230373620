
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-countdown-component] {
  &.save-the-date {
    background-color: $black;
    position: fixed;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1090;

    .container-fluid {
      background: linear-gradient(1deg, #10111D 1.74%, rgba(16, 17, 29, 0.0001) 100%);
    }

    .fs-big {
      font-size: 3.125rem;
    }

    .small {
      color: $player-grey;
    }

    .row {
      height: 100vh;
    }

    .icon {
      max-width: 100%;
    }

    .countdown {
      display: flex;
      justify-content: center;
      align-items: center;

      &__number {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .small {
          font-size: .5rem;
        }
      }

      &__separator {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 1.5rem;
      }
    }

    .btn-save {
      background: $white;
      border: 1.5px solid $white;
      border-radius: 14px;
      color: $black;
    }
  }
}
