
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[trivia-game-modal-component] {
  color: $black;

  .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    z-index: 1;
  }

  .nav-indicator {
    font-size: .75rem;
  }

  .trivia-carousel-item {
    min-height: calc(75vw * 0.56);

    .btn {
      width: 21.25rem;
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm});
      .btn {
        width: 100%;
      }
    }
  }

  .flickity-page-dots {
    bottom: 24px;

    .dot {
      pointer-events: none;
    }
  }

  .trivia-carousel__feedback {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    pointer-events: none;
    z-index: $zindex-sticky;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 25px;
    }
  }

  [versus-item] {
    .versus-answer-background-container {
      border-radius: $btn-border-radius;
      .versus-answer-background-progress {
        position: absolute;
        background-color: $gray-300;
        bottom: 0;
        left: 0;
        right: 0;

        .versus-answer-progress {
          height: 4px;
        }
      }
    }

    .btn-outline-primary {
      .versus-answer-progress { background-color: $primary }
    }

    .btn-outline-dark {
      .versus-answer-progress { background-color: $dark }
    }
  }
}
