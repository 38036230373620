@include media-breakpoint-up(xl) {
  .w-limiter {
    max-width: 2560px;
    margin-left: auto;
    margin-right: auto;
  }
}

.grecaptcha-badge {
  display: none;
}

@media (min-height: 1440px) {
  .mh-100vh, 
  .mh-100vh-header {
    height: 1440px !important;
    min-height: auto !important;
  }
}

.btn {
  text-transform: uppercase;
}

h3,
.h3 {
  line-height: 1.5;
}

.custom-checkbox {
  @include font-size(.5rem);
  background-color: $white;
  transition: background-color .25s ease-in-out;

  &:hover, &:focus, &:active,
  &.active {
    background-color: $black;
  }

  > .icon {
    transform: scale(1.75);
  }
}

.ar-9by2 {
  padding-bottom: 22.25%;
}

@include media-breakpoint-up(lg) {
  .ar-lg-9by2 {
    padding-bottom: 22.25% !important;
  }
}
