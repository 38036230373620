
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-eventbrite-component] {
  background-color: #eee;

  .sold-out {
    background-color: $danger;
    color: #fff;
  }

  .title, .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .title {
    -webkit-line-clamp: 2;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .expired-layer {
    background-color: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
  }
}
