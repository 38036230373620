
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[grid-eventbrite-component] {
  [tile-eventbrite-component] {
    background-color: #fff !important;

    .description {
      font-size: .825rem;
      line-height: 1.125rem;
    }
  }

  .placeholder {
    background-color: #fff;
  }
}
