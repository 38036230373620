
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoints-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .icon {
    width: 4rem;
    height: auto;
  }

  .item {
    max-width: 20rem;
  }
}
