
    @import "tenants/tenants-base/nettare_redazione/javascript/src/stylesheets/nettare_redazione_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$main-class: "player-progress";

.#{$main-class} {
  position: absolute;
  bottom: var(--player-control-bar-height);
  width: 100%;

  &-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    background: transparent;

    .time {
      font-weight: 400;
      font-size: .65rem;
      line-height: 5px;
      mix-blend-mode: normal;
      opacity: .75;
      min-width: 43px;
      color: $white;
    }

    .#{$main-class}-list {
      position: relative;
      cursor: pointer;

      ::v-deep {
        .vue-slider {
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 2px;

          &-piecewise-dot {
            visibility: visible !important;
            border-radius: 2px;
            height: 8px;

            &.default {
              background: $white;
            }
          }
        }
      }
    }
  }
}
